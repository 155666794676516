import axios from '../utils/axios';

export const doGenerateAnalyticsIssuesFullReportRequest = 'GENERATE_ANALYTICS_ISSUES_FULL_REPORT_REQUEST';
export const doGenerateAnalyticsIssuesFullReportSuccess = 'GENERATE_ANALYTICS_ISSUES_FULL_REPORT_SUCCESS';
export const doGenerateAnalyticsIssuesFullReportFailure = 'GENERATE_ANALYTICS_ISSUES_FULL_REPORT_FAILURE';

export const generateAnalyticsIssuesFullReportRequest = type => ({
    type: doGenerateAnalyticsIssuesFullReportRequest,
    payload: {
        type
    }
});

export const generateAnalyticsIssuesFullReportSuccess = data => ({
    type: doGenerateAnalyticsIssuesFullReportSuccess,
    payload: {
        data
    }
});

export const generateAnalyticsIssuesFullReportFailure = error => ({
    type: doGenerateAnalyticsIssuesFullReportFailure,
    payload: {
        error
    }
});

export const generateAnalyticsIssuesFullReport = (type, filters) => dispatch => {
    dispatch(generateAnalyticsIssuesFullReportRequest(type));

    console.log('filters', filters);

    return axios(`/api/report/issues/${type}`, {
        params: filters
    })
        .then(response => {
            dispatch(generateAnalyticsIssuesFullReportSuccess(response.data));

            return Promise.resolve(response.data);
        })
        .catch(err => {
            dispatch(generateAnalyticsIssuesFullReportFailure(err));

            return Promise.reject(err);
        });
};

export const doGenerateAnalyticsIssuesComfortReportRequest = 'GENERATE_ANALYTICS_ISSUES_COMFORT_REPORT_REQUEST';
export const doGenerateAnalyticsIssuesComfortReportSuccess = 'GENERATE_ANALYTICS_ISSUES_COMFORT_REPORT_SUCCESS';
export const doGenerateAnalyticsIssuesComfortReportFailure = 'GENERATE_ANALYTICS_ISSUES_COMFORT_REPORT_FAILURE';

export const generateAnalyticsIssuesComfortReportRequest = type => ({
    type: doGenerateAnalyticsIssuesComfortReportRequest,
    payload: {
        type
    }
});

export const generateAnalyticsIssuesComfortReportSuccess = data => ({
    type: doGenerateAnalyticsIssuesComfortReportSuccess,
    payload: {
        data
    }
});

export const generateAnalyticsIssuesComfortReportFailure = error => ({
    type: doGenerateAnalyticsIssuesComfortReportFailure,
    payload: {
        error
    }
});

export const generateAnalyticsIssuesComfortReport = (type, filters) => dispatch => {
    dispatch(generateAnalyticsIssuesComfortReportRequest(type));

    return axios(`/api/report/questionnaires/${type}`, {
        params: filters
    })
        .then(response => {
            dispatch(generateAnalyticsIssuesComfortReportSuccess(response.data));

            return Promise.resolve(response.data);
        })
        .catch(err => {
            dispatch(generateAnalyticsIssuesComfortReportFailure(err));

            return Promise.reject(err);
        });
};

export const doGenerateAnalyticsUsersReportRequest = 'GENERATE_ANALYTICS_USERS_REPORT_REQUEST';
export const doGenerateAnalyticsUsersReportSuccess = 'GENERATE_ANALYTICS_USERS_REPORT_SUCCESS';
export const doGenerateAnalyticsUsersReportFailure = 'GENERATE_ANALYTICS_USERS_REPORT_FAILURE';

export const generateAnalyticsUsersReportRequest = type => ({
    type: doGenerateAnalyticsUsersReportRequest,
    payload: {
        type
    }
});

export const generateAnalyticsUsersReportSuccess = data => ({
    type: doGenerateAnalyticsUsersReportSuccess,
    payload: {
        data
    }
});

export const generateAnalyticsUsersReportFailure = error => ({
    type: doGenerateAnalyticsUsersReportFailure,
    payload: {
        error
    }
});

export const generateAnalyticsUsersReport = (type, filters) => dispatch => {
    dispatch(generateAnalyticsUsersReportRequest(type));

    return axios(`/api/report/user/general/${type}`, {
        params: filters
    })
        .then(response => {
            dispatch(generateAnalyticsUsersReportSuccess(response.data));

            return Promise.resolve(response.data);
        })
        .catch(err => {
            dispatch(generateAnalyticsUsersReportFailure(err));

            return Promise.reject(err);
        });
};

export const doCheckAnalyticsIssuesFullReportReadyRequest = 'CHECK_ANALYTICS_ISSUES_FULL_REPORT_READY_REQUEST';
export const doCheckAnalyticsIssuesFullReportReadySuccess = 'CHECK_ANALYTICS_ISSUES_FULL_REPORT_READY_SUCCESS';
export const doCheckAnalyticsIssuesFullReportReadyFailure = 'CHECK_ANALYTICS_ISSUES_FULL_REPORT_READY_FAILURE';

export const checkAnalyticsIssuesFullReportReadyRequest = () => ({
    type: doCheckAnalyticsIssuesFullReportReadyRequest
});

export const checkAnalyticsIssuesFullReportReadySuccess = data => ({
    type: doCheckAnalyticsIssuesFullReportReadySuccess,
    payload: {
        data
    }
});

export const checkAnalyticsIssuesFullReportReadyFailure = error => ({
    type: doCheckAnalyticsIssuesFullReportReadyFailure,
    payload: {
        error
    }
});

export const checkAnalyticsIssuesFullReportReady = dates => dispatch => {
    dispatch(checkAnalyticsIssuesFullReportReadyRequest(dates));

    return axios('/api/report/issues/check', {
        method: 'get',
        params: dates
    })
        .then(response => {
            dispatch(checkAnalyticsIssuesFullReportReadySuccess(response.data));

            return Promise.resolve(response.data);
        })
        .catch(err => {
            dispatch(checkAnalyticsIssuesFullReportReadyFailure(err));

            return Promise.reject(err);
        });
};

export const doCheckAnalyticsIssuesComfortReportReadyRequest = 'CHECK_ANALYTICS_ISSUES_COMFORT_REPORT_READY_REQUEST';
export const doCheckAnalyticsIssuesComfortReportReadySuccess = 'CHECK_ANALYTICS_ISSUES_COMFORT_REPORT_READY_SUCCESS';
export const doCheckAnalyticsIssuesComfortReportReadyFailure = 'CHECK_ANALYTICS_ISSUES_COMFORT_REPORT_READY_FAILURE';

export const checkAnalyticsIssuesComfortReportReadyRequest = () => ({
    type: doCheckAnalyticsIssuesComfortReportReadyRequest
});

export const checkAnalyticsIssuesComfortReportReadySuccess = data => ({
    type: doCheckAnalyticsIssuesComfortReportReadySuccess,
    payload: {
        data
    }
});

export const checkAnalyticsIssuesComfortReportReadyFailure = error => ({
    type: doCheckAnalyticsIssuesComfortReportReadyFailure,
    payload: {
        error
    }
});

export const checkAnalyticsIssuesComfortReportReady = dates => dispatch => {
    dispatch(checkAnalyticsIssuesComfortReportReadyRequest(dates));

    return axios('/api/report/questionnaires/check', {
        method: 'get',
        params: dates
    })
        .then(response => {
            dispatch(checkAnalyticsIssuesComfortReportReadySuccess(response.data));

            return Promise.resolve(response.data);
        })
        .catch(err => {
            dispatch(checkAnalyticsIssuesComfortReportReadyFailure(err));

            return Promise.reject(err);
        });
};

export const doCheckAnalyticsUsersReportReadyRequest = 'CHECK_ANALYTICS_USERS_REPORT_READY_REQUEST';
export const doCheckAnalyticsUsersReportReadySuccess = 'CHECK_ANALYTICS_USERS_REPORT_READY_SUCCESS';
export const doCheckAnalyticsUsersReportReadyFailure = 'CHECK_ANALYTICS_USERS_REPORT_READY_FAILURE';

export const checkAnalyticsUsersReportReadyRequest = () => ({
    type: doCheckAnalyticsUsersReportReadyRequest
});

export const checkAnalyticsUsersReportReadySuccess = data => ({
    type: doCheckAnalyticsUsersReportReadySuccess,
    payload: {
        data
    }
});

export const checkAnalyticsUsersReportReadyFailure = error => ({
    type: doCheckAnalyticsUsersReportReadyFailure,
    payload: {
        error
    }
});

export const checkAnalyticsUsersReportReady = dates => dispatch => {
    dispatch(checkAnalyticsUsersReportReadyRequest(dates));

    return axios('/api/report/user/general/check', {
        method: 'get',
        params: dates
    })
        .then(response => {
            dispatch(checkAnalyticsUsersReportReadySuccess(response.data));

            return Promise.resolve(response.data);
        })
        .catch(err => {
            dispatch(checkAnalyticsUsersReportReadyFailure(err));

            return Promise.reject(err);
        });
};

export const doGenerateDetailedReportRequest = 'GENERATE_DETAILED_REPORT_REQUEST';
export const doGenerateDetailedReportSuccess = 'GENERATE_DETAILED_REPORT_SUCCESS';
export const doGenerateDetailedReportFailure = 'GENERATE_DETAILED_REPORT_FAILURE';

export const generateDetailedReportRequest = () => ({
    type: doGenerateDetailedReportRequest
});

export const generateDetailedReportSuccess = data => ({
    type: doGenerateDetailedReportSuccess,
    payload: {
        data
    }
});

export const generateDetailedReportFailure = error => ({
    type: doGenerateDetailedReportFailure,
    payload: {
        error
    }
});

export const generateDetailedReport = filters => dispatch => {
    dispatch(generateDetailedReportRequest(filters));

    return axios('/api/statistic/general/report/generate', {
        params: filters
    })
        .then(response => {
            dispatch(generateDetailedReportSuccess(response.data));

            return Promise.resolve(response.data);
        })
        .catch(err => {
            dispatch(generateDetailedReportFailure(err));

            return Promise.reject(err);
        });
};

export const doGenerateAnalyticsIssuesTermsReportRequest = 'GENERATE_ANALYTICS_ISSUES_TERMS_REPORT_REQUEST';
export const doGenerateAnalyticsIssuesTermsReportSuccess = 'GENERATE_ANALYTICS_ISSUES_TERMS_REPORT_SUCCESS';
export const doGenerateAnalyticsIssuesTermsReportFailure = 'GENERATE_ANALYTICS_ISSUES_TERMS_REPORT_FAILURE';

export const generateAnalyticsIssuesTermsReportRequest = () => ({
    type: doGenerateAnalyticsIssuesTermsReportRequest
});

export const generateAnalyticsIssuesTermsReportSuccess = data => ({
    type: doGenerateAnalyticsIssuesTermsReportSuccess,
    payload: {
        data
    }
});

export const generateAnalyticsIssuesTermsReportFailure = error => ({
    type: doGenerateAnalyticsIssuesTermsReportFailure,
    payload: {
        error
    }
});

export const generateAnalyticsIssuesTermsReport = filters => dispatch => {
    dispatch(generateAnalyticsIssuesTermsReportRequest(filters));

    return axios('/api/report/issues/counters/excel', {
        params: filters
    })
        .then(response => {
            dispatch(generateAnalyticsIssuesTermsReportSuccess(response.data));

            return Promise.resolve(response.data);
        })
        .catch(err => {
            dispatch(generateAnalyticsIssuesTermsReportFailure(err));

            return Promise.reject(err);
        });
};

export const doGenerateAnalyticsIssuesNotSolvedReportRequest = 'GENERATE_ANALYTICS_ISSUESNOTSOLVED_REPORT_REQUEST';
export const doGenerateAnalyticsIssuesNotSolvedReportSuccess = 'GENERATE_ANALYTICS_ISSUESNOTSOLVED_REPORT_SUCCESS';
export const doGenerateAnalyticsIssuesNotSolvedReportFailure = 'GENERATE_ANALYTICS_ISSUESNOTSOLVED_REPORT_FAILURE';

export const generateAnalyticsIssuesNotSolvedReportRequest = () => ({
    type: doGenerateAnalyticsIssuesNotSolvedReportRequest
});

export const generateAnalyticsIssuesNotSolvedReportSuccess = data => ({
    type: doGenerateAnalyticsIssuesNotSolvedReportSuccess,
    payload: {
        data
    }
});

export const generateAnalyticsIssuesNotSolvedReportFailure = error => ({
    type: doGenerateAnalyticsIssuesNotSolvedReportFailure,
    payload: {
        error
    }
});

export const generateAnalyticsIssuesNotSolvedReport = () => dispatch => {
    dispatch(generateAnalyticsIssuesNotSolvedReportRequest());

    return axios('/api/report/issues/were-not-answered/excel')
        .then(response => {
            dispatch(generateAnalyticsIssuesNotSolvedReportSuccess(response.data));

            return Promise.resolve(response.data);
        })
        .catch(err => {
            dispatch(generateAnalyticsIssuesNotSolvedReportFailure(err));

            return Promise.reject(err);
        });
};

export const doFetchListValuesForReportRequest = 'FETCH_LIST_VALUES_FOR_REPORT_REQUEST';
export const doFetchListValuesForReportSuccess = 'FETCH_LIST_VALUES_FOR_REPORT_SUCCESS';
export const doFetchListValuesForReportFailure = 'FETCH_LIST_VALUES_FOR_REPORT_FAILURE';

export const fetchListValuesForReportRequest = () => ({
    type: doFetchListValuesForReportRequest
});

export const fetchListValuesForReportSuccess = data => ({
    type: doFetchListValuesForReportSuccess,
    payload: {
        data
    }
});

export const fetchListValuesForReportFailure = error => ({
    type: doFetchListValuesForReportFailure,
    payload: {
        error
    }
});

export const fetchListValuesForReport = (target, value) => dispatch => {
    dispatch(fetchListValuesForReportRequest({[target]: value}));

    return axios(`/api/statistic/general/${target}/${value}`)
        .then(response => {
            dispatch(
                fetchListValuesForReportSuccess({
                    list: target,
                    items: response.data.map(i => ({
                        value: i.id,
                        label: i.name
                    }))
                })
            );

            return Promise.resolve(response);
        })
        .catch(err => {
            dispatch(fetchListValuesForReportFailure(err));

            return Promise.reject(err);
        });
};

export const getDownloadByTokenRequest = 'DOWNLOAD_BY_TOKEN_REQUEST';

export const downloadByTokenRequest = token => ({
    type: getDownloadByTokenRequest,
    payload: {
        token
    }
});

export const downloadByToken = token => dispatch => {
    dispatch(downloadByTokenRequest(token));

    window.open(`/api/storage/download/${token}`);
};

export const getRegionsDownloadSectionRequest = 'GET_REGIONS_DOWNLOAD_SECTION_REQUEST';
export const getRegionsDownloadSectionSuccess = 'GET_REGIONS_DOWNLOAD_SECTION_SUCCESS';
export const getRegionsDownloadSectionFailure = 'GET_REGIONS_DOWNLOAD_SECTION_FAILURE';

export const regionsDownloadSectionRequest = districtId => ({
    type: getRegionsDownloadSectionRequest,
    payload: {
        districtId
    }
});

export const regionsDownloadSectionSuccess = items => ({
    type: getRegionsDownloadSectionSuccess,
    payload: {
        items
    }
});

export const regionsDownloadSectionFailure = error => ({
    type: getRegionsDownloadSectionFailure,
    payload: {
        error
    }
});

export const getRegionsDownloadSection = districtId => dispatch => {
    dispatch(regionsDownloadSectionRequest());

    const url = districtId ? `/api/location/regions/district/${districtId}` : '/api/location/region';

    return axios(url, {
        params: {
            perPage: -1
        }
    })
        .then(response => {
            dispatch(regionsDownloadSectionSuccess(response.data.items));
        })
        .catch(err => {
            dispatch(regionsDownloadSectionFailure(err));
        });
};

export const getDistrictsDownloadSectionRequest = 'GET_DISTRICTS_DOWNLOAD_SECTION_REQUEST';
export const getDistrictsDownloadSectionSuccess = 'GET_DISTRICTS_DOWNLOAD_SECTION_SUCCESS';
export const getDistrictsDownloadSectionFailure = 'GET_DISTRICTS_DOWNLOAD_SECTION_FAILURE';

export const districtsDownloadSectionRequest = () => ({
    type: getDistrictsDownloadSectionRequest
});

export const districtsDownloadSectionSuccess = items => ({
    type: getDistrictsDownloadSectionSuccess,
    payload: {
        items
    }
});

export const districtsDownloadSectionFailure = error => ({
    type: getDistrictsDownloadSectionFailure,
    payload: {
        error
    }
});

export const getDistrictsDownloadSection = () => dispatch => {
    dispatch(districtsDownloadSectionRequest());

    return axios('/api/location/district', {
        params: {
            perPage: -1
        }
    })
        .then(response => {
            dispatch(districtsDownloadSectionSuccess(response.data.items));
        })
        .catch(err => {
            dispatch(districtsDownloadSectionFailure(err));
        });
};

export const getDistrictsByRegionDownloadSectionRequest = 'GET_DISTRICTS_BY_REGION_DOWNLOAD_SECTION_REQUEST';
export const getDistrictsByRegionDownloadSectionSuccess = 'GET_DISTRICTS_BY_REGION_DOWNLOAD_SECTION_SUCCESS';
export const getDistrictsByRegionDownloadSectionFailure = 'GET_DISTRICTS_BY_REGION_DOWNLOAD_SECTION_FAILURE';

export const districtsByRegionDownloadSectionRequest = regionId => ({
    type: getDistrictsByRegionDownloadSectionRequest,
    payload: {
        regionId
    }
});

export const districtsByRegionDownloadSectionSuccess = items => ({
    type: getDistrictsByRegionDownloadSectionSuccess,
    payload: {
        items
    }
});

export const districtsByRegionDownloadSectionFailure = error => ({
    type: getDistrictsByRegionDownloadSectionFailure,
    payload: {
        error
    }
});

export const getDistrictsByRegionDownloadSection = regionId => dispatch => {
    dispatch(districtsByRegionDownloadSectionRequest());

    if (!regionId) {
        dispatch(districtsByRegionDownloadSectionSuccess([]));

        return Promise.resolve({id: null, name: 'Все'});
    }

    return axios(`/api/location/district/region/${regionId}`, {
        params: {
            perPage: -1
        }
    })
        .then(response => {
            dispatch(districtsByRegionDownloadSectionSuccess(response.data.items));

            return Promise.resolve(response.data);
        })
        .catch(err => {
            dispatch(districtsByRegionDownloadSectionFailure(err));

            return Promise.reject(err);
        });
};
