import React from 'react';
import styles from './reportDownload.module.css';
import Select from '../../ui-kit/select/select.component';
import DatePickerRange from '../../ui-kit/datePicker/datePickerRange.component';
import DownloadIcon from '../../resources/svg/download.svg';
import DualRing from '../../resources/svg/dualRing.svg';

function ReportDownload(props) {
    const {
        analyticsLists = {},
        regionsList,
        districtList,
        regionsFetching,
        districtFetching,
        handleChangeDateFilter,
        handleUpdateListValue,
        downloadDetailedReport,
        handleChangeDateFilterFull,
        handleChangeDateFilterComfort,
        handleChangeDateFilterTerms,
        handleChangeDateFilterUsers,
        downloadIssuesFullReport,
        downloadIssuesComfortReport,
        downloadUsersReport,
        downloadIssuesTermsReport,
        downloadIssuesNotSolvedReport,
        handleDistrictChange,
        handleRegionChange,
        state
    } = props;

    console.log('state.reportValues', state.reportValues);

    return (
        <section className={styles.container}>
            <div className={styles.oneContainer}>
                <h2 className={styles.title}>Скачать статистический отчет по обращениям</h2>
                <div className={styles.filters}>
                    <Select
                        placeholder="Выберите федеральный округ"
                        className={styles.filter}
                        options={districtList}
                        disabled={districtFetching}
                        onChange={handleDistrictChange}
                        value={state.reportValues.district}
                    />
                    <Select
                        placeholder="Выберите регион"
                        className={styles.filter}
                        options={regionsList}
                        disabled={regionsFetching}
                        onChange={handleRegionChange}
                        value={state.reportValues.region}
                    />
                </div>
                <div className={styles.filters}>
                    <DatePickerRange
                        id="report-datepicker"
                        onChange={handleChangeDateFilter}
                        startDate={state.reportValues.created_from}
                        endDate={state.reportValues.created_to}
                        className={styles.filterDates}
                    />
                    <Select
                        placeholder="Выберите 1 список:"
                        className={styles.filterSecondary}
                        options={analyticsLists.row}
                        value={state.reportValues.row}
                        disabled={state.isReportListFetching || state.detailedReportGeneratingInProgress}
                        onChange={e => handleUpdateListValue('row', e)}
                    />
                    <Select
                        placeholder="Выберите 2 список:"
                        className={styles.filterSecondary}
                        options={analyticsLists.column}
                        value={state.reportValues.column}
                        disabled={state.isReportListFetching || state.detailedReportGeneratingInProgress}
                        onChange={e => handleUpdateListValue('column', e)}
                    />
                    <button
                        type="button"
                        className={styles.button}
                        onClick={() => downloadDetailedReport('excel')}
                        disabled={state.detailedReportGeneratingInProgress}
                    >
                        {state.detailedReportGeneratingInProgress ? (
                            <img className={styles.button_icon} src={DualRing} alt="logo" />
                        ) : (
                            <img className={styles.button_icon} src={DownloadIcon} alt="logo" />
                        )}
                        Скачать отчет
                    </button>
                </div>
            </div>
            <div className={styles.oneContainer}>
                <h2 className={styles.title}>Скачать список обращений</h2>
                <div className={styles.filters}>
                    <DatePickerRange
                        id="report-datepicker2"
                        onChange={handleChangeDateFilterFull}
                        startDate={state.reportValues.created_from}
                        endDate={state.reportValues.created_to}
                        className={styles.filterDates}
                    />
                    <button
                        type="button"
                        className={styles.button}
                        onClick={() => downloadIssuesFullReport('excel', state.reportValues.created_from_full, state.reportValues.created_to_full)}
                        disabled={state.fullReportGeneratingInProgress}
                    >
                        {state.fullReportGeneratingInProgress ? (
                            <img className={styles.button_icon} src={DualRing} alt="logo" />
                        ) : (
                            <img className={styles.button_icon} src={DownloadIcon} alt="logo" />
                        )}
                        Скачать отчет
                    </button>
                </div>
            </div>
            <div className={styles.oneContainer}>
                <h2 className={styles.title}>Скачать отчет по удобству использования функционала Системы</h2>
                <div className={styles.filters}>
                    <DatePickerRange
                        id="report-datepicker3"
                        onChange={handleChangeDateFilterComfort}
                        startDate={state.reportValues.created_from}
                        endDate={state.reportValues.created_to}
                        className={styles.filterDates}
                    />
                    <button
                        type="button"
                        className={styles.button}
                        onClick={() => downloadIssuesComfortReport('excel', state.reportValues.created_from, state.reportValues.created_to)}
                        disabled={state.comfortReportGeneratingInProgress}
                    >
                        {state.comfortReportGeneratingInProgress ? (
                            <img className={styles.button_icon} src={DualRing} alt="logo" />
                        ) : (
                            <img className={styles.button_icon} src={DownloadIcon} alt="logo" />
                        )}
                        Скачать отчет
                    </button>
                </div>
            </div>
            <div className={styles.oneContainer}>
                <h2 className={styles.title}>Скачать отчет о сроках рассмотрения обращений</h2>
                <div className={styles.filters}>
                    <DatePickerRange
                        id="report-datepicker4"
                        onChange={handleChangeDateFilterTerms}
                        startDate={state.reportValues.created_from}
                        endDate={state.reportValues.created_to}
                        className={styles.filterDates}
                    />
                    <button
                        type="button"
                        className={styles.button}
                        onClick={() => downloadIssuesTermsReport(state.reportValues.created_from, state.reportValues.created_to)}
                        disabled={state.termsReportGeneratingInProgress}
                    >
                        {state.termsReportGeneratingInProgress ? (
                            <img className={styles.button_icon} src={DualRing} alt="logo" />
                        ) : (
                            <img className={styles.button_icon} src={DownloadIcon} alt="logo" />
                        )}
                        Скачать отчет
                    </button>
                </div>
            </div>
            <div className={styles.oneContainer}>
                <h2 className={styles.title}>Скачать базу пользователей</h2>
                <div className={styles.filters}>
                    <DatePickerRange
                        id="report-datepicker5"
                        onChange={handleChangeDateFilterUsers}
                        startDate={state.usersValues.created_from}
                        endDate={state.usersValues.created_to}
                        className={styles.filterDates}
                    />
                    <button
                        type="button"
                        className={styles.button}
                        onClick={() => downloadUsersReport('excel', state.usersValues.created_from, state.usersValues.created_to)}
                        disabled={state.usersReportGeneratingInProgress}
                    >
                        {state.usersReportGeneratingInProgress ? (
                            <img className={styles.button_icon} src={DualRing} alt="logo" />
                        ) : (
                            <img className={styles.button_icon} src={DownloadIcon} alt="logo" />
                        )}
                        Скачать отчет
                    </button>
                </div>
            </div>
            <div className={styles.oneContainer}>
                <h2 className={styles.title}>Скачать номера обращений, которые были в статусе "Проблема не решена"</h2>
                <div className={styles.filters}>
                    <button
                        type="button"
                        className={styles.button}
                        onClick={() => downloadIssuesNotSolvedReport('excel')}
                        disabled={state.issuesNotSolvedReportGeneratingInProgress}
                    >
                        {state.issuesNotSolvedReportGeneratingInProgress ? (
                            <img className={styles.button_icon} src={DualRing} alt="logo" />
                        ) : (
                            <img className={styles.button_icon} src={DownloadIcon} alt="logo" />
                        )}
                        Скачать отчет
                    </button>
                </div>
            </div>
        </section>
    );
}

export default ReportDownload;
